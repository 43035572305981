<template>
    <a-alert type="error" v-if="accountType === 'merchant' && !wxService">
        <template #message>
            <div class="flex items-center">
                <ExclamationCircleOutlined class="mr-2"/>
                请尽快完成绑定企业微信客服，否则将可能导致店铺交易和结算异常
                <span @click="configuration" :style="{ marginLeft: '15px', color: '#0080ff', cursor: 'pointer' }">
          去配置
        </span>
            </div>
        </template>
    </a-alert>
    <ThemeProvider
            is-root
            v-bind="themeConfig"
            :apply-style="false"
            :color="{
      middle: {
        'text-base': '#242f57',
        'bg-side': '#1e1e1e',
      },
    }"
    >
        <a-config-provider :locale="zh_CN">
            <stepin-view
                    system-name="念想旅游"
                    :logo-src="avatar"
                    :class="`${contentClass}`"
                    :user="user"
                    :useTabs="useTabs"
                    :navMode="navigation"
                    :menuList="menuList"
            >
                <template #headerActions></template>
            </stepin-view>
        </a-config-provider>
    </ThemeProvider>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {useRouter} from 'vue-router';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import {storeToRefs, useAccountStore, useMenuStore, useSettingStore} from '@/store';
import avatar from '@/assets/image/logo.png';
import {themeList} from '@/theme';
import {ThemeProvider} from 'stepin';
import {useThemeStore} from 'stepin/es/theme-provider';
import {complaintSum} from '@/services/common';

const wxService = computed(() => useAccountStore().currentShop.wxService);
const accountType = computed(() => useAccountStore().account.accountType);
const {logout} = useAccountStore();
const {setPrimaryColor} = useThemeStore();
setPrimaryColor({DEFAULT: '#ff3f27'});

const {navigation, useTabs, theme, contentClass} = storeToRefs(useSettingStore());
const themeConfig = computed(() => themeList.find((item) => item.key === theme.value)?.config ?? {});
const account = computed(() => useAccountStore().account);
const router = useRouter();

const getComplaintSum = async () => {
    const res: any = await complaintSum();
    num.value = res.info;
};

// const token = localStorage.getItem('access_token');
// if (token) {
//   getComplaintSum();
// }
const num = ref(0);

const menuList = computed(() => {
    let list = useMenuStore().menuList;
    let loginJump = useAccountStore().loginJump;
    const token = localStorage.getItem('access_token');
    if (token) {
        getComplaintSum();
    }
    const arr = list.map((item: any) => {
        if (item.renderMenu === 1) {
            return {
                title: item.title,
                path: item.path,
                meta: {
                    icon: item.icon,
                },
                children: item.children
                    .filter((child) => child.renderMenu === 1) // 过滤掉 renderMenu 不等于 1 的项
                    .map((child) => ({
                        title: child.title === '投诉管理' && num.value > 0 ? `${child.title}(${num.value})` : child.title,
                        path: child.path,
                        children:
                            child.children &&
                            child.children
                                .filter((grandson) => grandson.renderMenu === 1) // 过滤掉 renderMenu 不等于 1 的项
                                .map((grandson) => ({
                                    title:
                                        grandson.title === '投诉管理' && num.value > 0
                                            ? `${grandson.title}(${num.value})`
                                            : grandson.title,
                                    path: grandson.path,
                                })),
                    })),
            };
        }
    });

    return arr;
});
const user = computed(() => ({
    name: account.value.nickname,
    avatar: account.value.avatar || avatar,
    menuList: [
        {
            title: '个人信息',
            key: 'personal',
            icon: 'UserOutlined',
            onClick: () => router.push('/personal'),
        },
        {
            title: '账号设置',
            key: 'account',
            icon: 'SettingOutlined',
            onClick: () => router.push('/account'),
        },
        {
            title: '退出登录',
            key: 'logout',
            icon: 'LogoutOutlined',
            onClick: () => logout().then(() => router.push('/login')),
        },
    ],
}));

useMenuStore().addRoute();

const configuration = () => {
    router.push('/store-setting');
};

// 获取个人信息
</script>

<style lang="less">
.stepin-view {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: theme('colors.primary.500');
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: theme('colors.primary.400');

    &:hover {
      background-color: theme('colors.primary.500');
    }
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 4px;
    background: theme('backgroundColor.layout');
  }
}

html {
  height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
}

.stepin-img-checkbox {
@apply transition-transform;

  &:hover {
  @apply scale-105 ~ "-translate-y-[2px]";
  }

  img {
  @apply shadow-low rounded-md transition-transform;
  }
}

.items-center {
  color: #ffaa0b;
  justify-content: center;
}
</style>
