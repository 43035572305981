<template>
    <IconFont class="icon-storeMarketing" name="icon-a-dianpuyingxiaosbeifen2x"/>
</template>

<script>
export default {
    name: 'icon-storeMarketing',
};
</script>

<style>
.icon-storeMarketing {
    font-size: 16px;
}
</style>
