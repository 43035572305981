<template>
    <div class="mainTable">
        <a-table
                :rowKey="rowKey"
                :columns="columns"
                :dataSource="dataList"
                :pagination="{
        pageSize: pageSize,
        current: currentPage,
        total: total,
        size: 'small',
        showSizeChanger: false,
        showTotal: (total) => `共 ${total} 条`,
      }"
                :loading="loading"
                @change="onChange"
                selection
                :row-selection="selection ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null"
                :scroll="{ x: scrollX, y: scrollY }"
        >
            <slot></slot>
            <!-- <template #title>
              <slot name="title"></slot>
            </template> -->

            <template #bodyCell="{ text, record, index, column }">
                <slot name="bodyCell" :text="text" :record="record" :index="index" :column="column"></slot>
            </template>
            <template #headerCell="{ title, column }">
                <slot name="headerCell" :title="title" :column="column"></slot>
            </template>
        </a-table>
    </div>
</template>
<script>
export default {
    name: 'main-table',
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        rowKey: {
            type: String,
            default: 'id',
        },
        apiObj: {type: Function, default: null},
        params: {type: Object, default: () => ({})},
        selection: {type: Boolean, default: false},
        selectChange: {type: Function, default: null},
        scrollX: {type: Number, default: 0},
        scrollY: {type: Number, default: null},
        selectedRowKeys: {type: Array, default: () => []},
    },
    data() {
        return {
            dataList: [],
            selectedRows: [],
            loading: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            selectList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getdata() {
            this.currentPage = 1;
            this.getList();
        },
        async getList() {
            this.loading = true;
            let reqData = {
                pageNo: this.currentPage,
                pageSize: this.pageSize,
                ...this.params,
            };
            const res = await this.apiObj(reqData);
            if (res.code === 1) {
                this.loading = false;
                this.dataList = res.list;
                this.total = res.pageTurn.rowCount;
            }
        },
        onChange(pagination, filter, sorter) {
            const {current} = pagination;
            this.currentPage = current;
            this.$emit('sorterChange', sorter);
            this.getList();
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectChange(selectedRowKeys, selectedRows);
        },
    },
};
</script>
<style lang="less" scoped>
.mainTable {
  margin-top: 20px;
  width: 100%;
}
</style>
