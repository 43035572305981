import {NavigationGuard, NavigationHookAfter} from 'vue-router';
import http from '@/store/http';
import {useMenuStore} from '@/store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({showSpinner: false});

interface NaviGuard {
    before?: NavigationGuard;
    after?: NavigationHookAfter;
}

const loginGuard: NavigationGuard = function (to, from) {
    // if (/^\/$/.test(to.fullPath)) {
    //   return '/login';
    // }
    if (!http.checkAuthorization() && !/^\/(login)?$/.test(to.fullPath)) {
        return '/login';
    }
};
// 进度条
const ProgressGuard: NaviGuard = {
    before(to, from) {
        NProgress.start();
    },
    after(to, from) {
        NProgress.done();
    },
};

// 404 not found
const NotFoundGuard: NaviGuard = {
    before(to, from) {
        const {loading} = useMenuStore();
        if (to.meta._is404Page && loading) {
            to.params.loading = true as any;
        }
    },
};

export default {
    before: [ProgressGuard.before, loginGuard, NotFoundGuard.before],
    after: [ProgressGuard.after],
};
