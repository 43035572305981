<template>
    <IconFont class="icon-order" name="icon-a-dingdanguanlisbeifen2x"/>
</template>

<script>
export default {
    name: 'icon-order',
};
</script>

<style>
.icon-order {
    font-size: 16px;
}
</style>
