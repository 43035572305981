import http from '@/store/http';
import umiRequest from 'umi-request';
import progressMiddleware from 'umi-request-progress';
// umiRequest.use(progressMiddleware, { core: true });
import NProgress from 'nprogress';

export const uploadFile = ({ filename, path, file }) => {
  return new Promise((resolve, reject) => {
    http
      .request('/operate/file/upload', 'POST_JSON', { filename, path })
      .then(async (res: any) => {
        if (res.code === 1) {
          const { uploadUrl, downloadUrl, contentType } = res.info;
          if (!NProgress.isStarted()) {
            NProgress.start();
          }
          await umiRequest.put(uploadUrl, {
            headers: { 'Content-Type': contentType },
            data: file,
          });
          NProgress.done();
          resolve(downloadUrl);
        } else {
            NProgress.done();
          reject(new Error('Upload failed.'));
        }
      })
      .catch((error) => {
        NProgress.done();
        reject(error);
      });
  });
};

export function complaintSum() {
  return http.request('/operate/complaint/sum', 'GET');
}
