<template>
    <IconFont class="icon-finance" name="icon-a-caiwuguanlisbeifen2x"/>
</template>

<script>
export default {
    name: 'icon-finance',
};
</script>

<style>
.icon-finance {
    font-size: 18px;
}
</style>
