import { AxiosRequestConfig, AxiosResponse } from 'axios';
import createHttp from '@/utils/axiosHttp';
import NProgress from 'nprogress';
import { useAccountStore } from './index';
import { message } from 'ant-design-vue';

const http = createHttp({
  timeout: 15000,
  baseURL: '/mall',
});

const isAxiosResponse = (obj: any): obj is AxiosResponse => {
  return typeof obj === 'object' && obj.status && obj.statusText && obj.headers && obj.config;
};

// progress 进度条 -- 开启
http.interceptors.request.use((req: AxiosRequestConfig) => {
  req.headers['Authorization'] = window.localStorage.getItem('access_token') || '';
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  return req;
});

// 解析响应结果
http.interceptors.response.use(
  (rep: AxiosResponse<String>) => {
    // progress 进度条 -- 关闭
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    const { data }: any = rep;
    if (data.code === 1) {
      return data;
    } else {
      message.error(data.tip || '请求失败');
    }
    if (data.code === 9) {
      // useAccountStore().logout();
      // window.location.href = '/login';
    }
    return Promise.reject(data);
  },
  (error) => {
    // progress 进度条 -- 关闭
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    if (error.response.status === 401) {
      window.localStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    if (error.response && isAxiosResponse(error.response)) {
      return Promise.reject({
        message: error.response.statusText,
        code: error.response.status,
        data: error.response.data,
      });
    }
    return Promise.reject(error);
  }
);

export default http;
