<template>
    <IconFont class="icon-mall" name="icon-a-shangchengguanlisbeifen2x"/>
</template>

<script>
export default {
    name: 'icon-mall',
};
</script>

<style>
.icon-mall {
    font-size: 16px;
}
</style>
