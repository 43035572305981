import http from '@/store/http';

export const userLogin = (data: any) => {
  return http.request('/operate/security/login/password', 'POST_JSON', data);
};

export const phoneLogin = (data: any) => {
  return http.request('/operate/security/login/phone', 'POST_JSON', data);
};

export const sendCaptcha = (data: any) => {
  return http.request('/operate/sendCaptcha', 'POST_JSON', data);
};

export const setPassword = (data: any) => {
  return http.request('/operate/security/settingPassword', 'POST_JSON', data);
};

export const userList = (data: any) => {
  return http.request('/operate/user', 'GET', data);
};

export const setUser = (data: any) => {
  return http.request('/operate/user', 'POST_JSON', data);
};

export const getUserInfo = (data: any) => {
  return http.request(`/operate/user/${data}`, 'GET');
};

export const modifyState = (data: any) => {
  return http.request('/operate/user/status', 'POST_JSON', data);
};

export const roleList = (data?: any) => {
  return http.request('/operate/role', 'GET', data);
};

export const menusList = () => {
  return http.request('/operate/menus', 'GET');
};

export const roleInfo = (data: any) => {
  return http.request(`/operate/role/${data}`, 'GET');
};

export const deleteRole = (data: any) => {
  return http.request(`/operate/role/${data}`, 'DELETE');
};

export const setRole = (data: any) => {
  return http.request('/operate/role', 'POST_JSON', data);
};

export const authority = (data?: any) => {
  return http.request('/operate/user/recourses', 'GET', data);
};

export const addMenus = (data?: any) => {
  return http.request('/operate/menus', 'POST_JSON', data);
};

export const deleteMenu = (data?: any) => {
  return http.request(`/operate/menus/${data}`, 'DELETE');
};

export const updateUserInfo = (data?: any) => {
  return http.request('/operate/user/updateUserInfo', 'POST_JSON', data);
};

export const validMobile = (data?: any) => {
  return http.request('/operate/user/validMobile', 'POST_JSON', data);
};

export const postUpdateAccount = (data?: any) => {
  return http.request('/operate/user/updateAccount', 'POST_JSON', data);
};

export const postUpdatePassword = (data?: any) => {
  return http.request('/operate/user/updatePassword', 'POST_JSON', data);
};

export const addActions = (data?: any) => {
  return http.request(`/operate/menus/actions/${data.menuId}`, 'PUT_JSON', data.actions);
};

export const channelPromoterInfoAdd = (data?: any) => {
  return http.request(`/operate/channelPromoterInfo/add`, 'POST_JSON', data);
};

export const channelPromoterInfoPage = (data?: any) => {
  return http.request(
    `operate/channelPromoterInfo/page?page=${data.page}&size=${data.size}&keyType=${data.keyType}&keyWord=${data.keyWord}&status=${data.status}&guildId=${data.guildId}`,
    'GET',
  );
};

export const channelPromoterInfoUpdate = (data?: any) => {
  return http.request(`/operate/channelPromoterInfo/update`, 'POST_JSON', data);
};

export const geuGuildData = (data?: any) => {
  return http.request(`operate/channelGuildOwners/select`, 'GET');
};
