<template>
    <IconFont class="icon-operation" name="icon-a-yunyingpeizhisbeifen2x"/>
</template>

<script>
export default {
    name: 'icon-operation',
};
</script>

<style>
.icon-operation {
    font-size: 16px;
}
</style>
