<template>
    <IconFont class="icon-user" name="icon-a-yonghuguanlisbeifen2x"/>
</template>

<script>
export default {
    name: 'icon-user',
};
</script>

<style>
.icon-user {
    font-size: 16px;
}
</style>