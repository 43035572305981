import { defineStore } from 'pinia';
import http from './http';
import { useMenuStore } from './menu';
import { useAuthStore } from '@/plugins';
import { message } from 'ant-design-vue';
import { authority, phoneLogin, userLogin } from '@/services/user';

export interface Account {
  username: string;
  avatar: string;
  userId: number;
  accountType: string;
  shopName: string;
  account: string;
}

export type TokenResult = {
  access_token: string;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: JSON.parse(window.localStorage.getItem('account') || '{}'),
      permissions: JSON.parse(window.localStorage.getItem('permission')) || ([] as string[]),
      logged: true,
      token: window.localStorage.getItem('access_token') || '',
      shopList: JSON.parse(window.localStorage.getItem('shopList')) as any,
      currentShop: JSON.parse(window.localStorage.getItem('currentShop') || '{}'),
      loginJump: false,
    };
  },
  actions: {
    login(username, password) {
      return new Promise((resolve, reject) => {
        userLogin({ username, password, type: 'operate' })
          .then((response: any) => {
            if (response.code === 1) {
              this.logged = true;
              http.setAuthorization(response.info.token);
              this.token = response.info.token;
              this.shopList = response.info.shopNameInfos;
              message.success('登录成功');
              resolve(response.info);
            } else {
              message.error(response.tip);
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    phoneLogin(phone: string, captcha: string) {
      return new Promise((resolve, reject) => {
        phoneLogin({ phone, captcha })
          .then(async (response: any) => {
            if (response.code === 1) {
              this.logged = true;
              http.setAuthorization(`${response.info.token}`);
              this.token = response.info.token;
              this.shopList = response.info.shopNameInfos;
              window.localStorage.setItem('shopList', JSON.stringify(response.info.shopNameInfos));
              message.success('登录成功');
              resolve(response.info);
            } else {
              message.error(response.tip);
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAuthority(shopId?: number) {
      return new Promise((resolve, reject) => {
        authority({ shopId }).then((res: any) => {
          if (res.code === 1) {
            const { userMenu, actions, nickname, avatar, userId, accountType, currentShop, account } = res.info;

            this.setAccount({
              nickname,
              avatar,
              userId,
              accountType,
              account,
            });
            this.setCurrentShop(currentShop);
            useMenuStore().getMenuList(userMenu);
            this.setProfile(actions);
            resolve(res);
          } else {
            message.error(res.tip);
            reject(res);
          }
        });
      });
    },

    changeShop(shopId: number) {
      return new Promise((resolve, reject) => {
        authority({ shopId }).then((res: any) => {
          if (res.code === 1) {
            const { nickname, avatar, userId, accountType, currentShop, account, actions, userMenu } = res.info;
            this.setAccount({
              nickname,
              avatar,
              userId,
              accountType,
              account,
            });
            useMenuStore().setMenu([]);
            this.setCurrentShop(currentShop);
            useMenuStore().getMenuList(userMenu);
            this.setProfile(actions);
            resolve(res);
          } else {
            message.error(res.tip);
            reject(res);
          }
        });
      });
    },

    async logout() {
      return new Promise<boolean>((resolve) => {
        localStorage.removeItem('menuList');
        localStorage.removeItem('permission');
        localStorage.removeItem('account');
        localStorage.removeItem('currentShop');
        this.setAccount({} as Account);
        this.setCurrentShop({} as any);
        useMenuStore().setMenu([]);
        http.removeAuthorization();
        this.logged = false;
        resolve(true);
      });
    },

    setProfile(permissions: any) {
      const { setAuthorities } = useAuthStore();
      this.permissions = permissions.map((item: any) => item);
      window.localStorage.setItem('permission', JSON.stringify(permissions.map((item: any) => item)));
      setAuthorities(permissions);
    },
    setAccount(account: Account) {
      window.localStorage.setItem('account', JSON.stringify({ ...account }));
      this.account = account;
    },
    updateAccount(item) {
      this.account = { ...this.account, ...item };
      window.localStorage.setItem('account', JSON.stringify({ ...this.account }));
    },
    setCurrentShop(currentShop: any) {
      window.localStorage.setItem('currentShop', JSON.stringify({ ...currentShop }));
      this.currentShop = currentShop;
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
    setToken(token: string) {
      this.token = token;
    },
    setloginJump(loginJump: boolean) {
      this.loginJump = loginJump;
    },
  },
});
