<template>
    <IconFont class="icon-product" name="icon-a-shangpinguanlisbeifen2x"/>
</template>

<script>
export default {
    name: 'icon-product',
};
</script>

<style>
.icon-product {
    font-size: 16px;
}
</style>
