import {defineStore, storeToRefs} from 'pinia';
import {ref, watch} from 'vue';
import {RouteOption} from '@/router/interface';
import {addRoutes, removeRoute} from '@/router/dynamicRoutes';
import {useSettingStore} from './setting';
import {RouteMeta, RouteRecordRaw} from 'vue-router';
import {useAuthStore} from '@/plugins';
import router from '@/router';

export interface MenuProps {
    id?: number;
    name: string;
    path: string;
    title?: string;
    icon?: string;
    badge?: number | string;
    target?: '_self' | '_blank';
    link?: string;
    component: string;
    renderMenu?: boolean;
    permission?: string;
    parent?: string;
    children?: MenuProps[];
    cacheable?: boolean;
    view?: string;
}

/**
 * 过滤菜单
 * @param routes
 * @param parentPermission
 */
function doMenuFilter(routes: Readonly<RouteRecordRaw[]>, parentPermission?: string) {
    const {hasAuthority} = useAuthStore();

    const setCache = (meta: RouteMeta) => {
        meta._cache = {
            renderMenu: meta.renderMenu,
        };
    };

    routes.forEach((route) => {
        const required = route.meta?.permission ?? parentPermission;
        // if (route.meta?.renderMenu === undefined && required) {
        if (required) {
            route.meta = route.meta ?? {};
            setCache(route.meta);
            route.meta.renderMenu = hasAuthority(route.meta.permission);
        }
        if (route.children) {
            doMenuFilter(route.children, required);
        }
    });
}

/**
 * 重置过滤
 * @param routes
 */
function resetMenuFilter(routes: Readonly<RouteRecordRaw[]>) {
    const resetCache = (meta: RouteMeta) => {
        if (meta._cache) {
            meta.renderMenu = meta._cache?.renderMenu;
        }
        delete meta._cache;
    };
    routes.forEach((route) => {
        if (route.meta) {
            resetCache(route.meta);
        }
        if (route.children) {
            resetMenuFilter(route.children);
        }
    });
}

// 菜单数据转为路由数据
const toRoutes = (list: MenuProps[]): RouteOption[] => {
    return list.map((item) => ({
        name: item.name,
        path: item.path,
        component: item.component,
        children: item.children && toRoutes(item.children),
        meta: {
            title: item.title,
            permission: item.permission,
            icon: item.icon,
            renderMenu: item.renderMenu,
            cacheable: item.cacheable,
            href: item.link,
            badge: /^(false|true)$/i.test(item.badge + '') ? JSON.parse(item.badge + '') : item.badge,
            target: item.target,
            view: true,
        },
    }));
};

export const useMenuStore = defineStore('menu', () => {
    const menuList = ref<MenuProps[]>(JSON.parse(window.localStorage.getItem('menuList') || '[]'));
    const loading = ref(true);
    loading.value = true;
    const {filterMenu} = storeToRefs(useSettingStore());
    const checkMenuPermission = () => {
        if (filterMenu.value) {
            doMenuFilter(router.options.routes);
        } else {
            resetMenuFilter(router.options.routes);
        }
    };

    // checkMenuPermission();

    watch(filterMenu, checkMenuPermission);

    async function getMenuList(data) {
        window.localStorage.setItem('menuList', JSON.stringify(data));
        addRoutes(toRoutes(data));
        menuList.value = data;
        checkMenuPermission();
        loading.value = false;
    }

    const addRoute = () => {
        addRoutes(toRoutes(menuList.value));
        checkMenuPermission();
        setTimeout(() => {
            loading.value = false;
        }, 1200);
    };

    const setMenu = (data) => {
        removeRoute(menuList.value);
        menuList.value = data;
    };

    return {
        loading,
        menuList,
        getMenuList,
        addRoute,
        setMenu,
    };
});
