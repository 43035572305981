// context(目录路径，是否加载子目录，加载文件的匹配正则)
const importFn = import.meta.globEager('./*.vue');


export default {
    install(app) {
        for (const path in importFn) {
            if (Object.hasOwnProperty.call(importFn, path)) {
                const component = importFn[path].default;
                app.component(component.name, component);
            }
        }
    },
};
