import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: '前端',
        meta: {
            renderMenu: false,
        },
        redirect: '/login',
        children: [
            {
                path: '/login',
                name: '登录',
                meta: {
                    icon: 'LoginOutlined',
                    view: 'blank',
                    target: '_blank',
                    cacheable: false,
                },
                component: () => import('@/pages/login/Login.vue'),
            },
        ],
    },

    {
        path: '/userCenter',
        name: '用户中心',
        meta: {
            renderMenu: false,
        },
        component: () => import('@/pages/userCenter/index.vue'),
        redirect: '/personal',
        children: [
            {
                path: '/personal',
                name: '个人信息',
                component: () => import('@/pages/userCenter/personal.vue'),
            },
            {
                path: '/account',
                name: '账号设置',
                component: () => import('@/pages/userCenter/account.vue'),
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        props: true,
        meta: {
            icon: 'CreditCardOutlined',
            renderMenu: false,
            cacheable: false,
            _is404Page: true,
        },
        component: () => import('@/pages/Exp404.vue'),
    },
];

export default routes;
